<template>
  <section class="reg">
    <h2>司机招募隐私政策</h2>
    <b>更新日期: 2023 年 9 月12 日</b><br />
    <b>生效日期: 2023  年 9月19 日</b> 

    <p style="margin-top: 8px">浙江礼帽出行有限公司及其关联公司（下文简称“礼帽出行”或“我们”），常用办公地址：浙江省杭州市滨江区聚光中心A座12楼，联系电话：400 009 0909。感谢您关注并使用我们的产品及/或服务，我们深知隐私对您的重要性，并将尽全力保障您的个人信息及隐私安全，并制定了本隐私政策（下文简称“本政策”）。
    </p><p>
    本政策适用于礼帽出行官网（ https://www.limaocx.com/ ）中司机招募页面中司机留资功能模块。但本政策不适用于：
    </p><p>· 任何其他第三方向您提供的产品及服务；
    </p><p>· 具有单独的隐私政策，并且这些政策并未纳入本政策的特定产品或服务。
    </p><p>
    本政策阐述了我们如何处理您的个人信息，并为您提供访问和控制您的个人信息的途径。在为您提供优质的产品和服务的同时，我们将根据本政策收集、使用、共享、存储和保护您的个人信息。
</p><p>
    请您向礼帽出行提交个人信息之前，认真阅读并充分理解本政策，尤其对于加粗部分内容请您重点关注。一旦您主动提交个人信息，即表示您已充分理解并同意我们按照本政策的相关内容，对您的个人信息进行收集、处理和使用。如您对本政策存在任何疑问，或有任何投诉、建议，请通过本政策所示方式联系我们。
</p><p>
    我们制定本政策的目的在于帮助您了解以下内容：
    </p><p>1. 我们如何收集和使用您的个人信息
    </p><p>2. 我们如何共享、转让及披露您的个人信息
    </p><p>3. 我们如何存储和保护您的个人信息
    </p><p>4. 如何访问和控制您的个人信息
    </p><p>5. 未成年人个人信息的处理
    </p><p>6. 联系我们
    </p><p>7. 隐私政策的更新
</p>
    <h3>一、我们如何收集和使用您的个人信息</h3>
    <p>若您有意向加盟成为礼帽出行的司机，您可以通过本网站司机招募页面中司机留资功能模块向我们提供必要信息包括：您的称呼、手机号码。如果您仅在官网进行基础浏览等活动，您不需要提供上述信息。
    </p><p>我们将基于您提供的上述信息安排我们或授权合作商的工作人员及时与您联系，沟通合作意向，并为您提供您需要的服务。
</p>
    <h3>二、我们如何共享、转让及披露您的个人信息</h3>
    <p>为持续提供高效优质的产品和服务，我们将在遵循统一管理和控制的关联公司之间共享您的个人信息，且受本隐私政策中所声明目的的约束。
    </p><p>此外，我们可能委托合作伙伴为您提供某些服务或代表我们履行某些职能，我们仅会出于本政策声明目的委托其处理，我们会通过协议要求其不得将您的个人信息用于其他任何超出委托范围的目的，并要求其采取足够的安全保障措施。我们不会将您的个人信息转让给任何公司、组织和个人，或者将您的个人信息进行公开，法律法规另有规定或者您明确同意的除外。
</p>
    <h3>三、我们如何存储和保护您的个人信息</h3>
    <h4>3.1. 个人信息安全保障</h4>
    <p>我们非常重视您的个人信息安全，将尽最大努力保障您的个人信息安全，采取符合业界标准、合理可行的安全防护措施，防止数据遭到未经授权的访问、披露、使用、修改、损坏、丢失或泄露。但请您理解，任何安全措施都无法做到百分百安全。如果您有理由认为我们的服务存在信息安全风险，请及时告知我们。
    </p><p>如果不幸发生个人信息安全事件，我们会启动应急预案，采取合理的救济措施，以尽可能降低对您个人信息和其他权益的影响；我们还将按照法律法规的要求向您告知，或上报个人信息安全事件的处置情况。
</p>
    <h4>3.2. 个人信息的存储</h4>
    <p>我们会按照法律法规规定，将境内收集的用户个人信息存储于中国境内。目前，我们不会将您的个人信息在跨境业务中使用、储存、共享和披露，或将您的个人信息传输至境外。
    </p><p>我们仅在为实现业务目的所必需的期限内保留您的个人信息。超出上述期限，我们将对个人信息进行删除或匿名化处理，法律法规另有规定的除外。
    </p>
    <h3>四、如何访问和控制您的个人信息</h3>
    <p>按照中华人民共和国相关的法律、法规、标准，我们保障您对自己的个人信息行使以下权利包括①知悉对个人信息的处理情况；②查阅个人信息；③复制个人信息；④转移个人信息；⑤更正、补充个人信息；⑥删除个人信息；⑦决定、限制和拒绝处理个人信息；⑧撤回已同意的授权。您可以通过“联系我们”展现中的方式联系我们以实现前述权利。其中，部分权利的控制方式具体如下：
</p>
    <h4>4.1. 访问、更正/修改您的个人信息</h4>
    <p>您享有访问、编辑、更正/修改您个人信息的权利，如您需要访问、编辑、更正、修改我们收集的您的个人信息，您可以通过下述“联系我们”所载明的联系方式与我们联系，我们会及时为您处理。
</p>
    <h4>4.2 获取您的个人信息副本</h4>
    <p>如您需要获取我们收集的您的个人信息副本，您可通过下述“联系我们“所载明的联系方式与我们联络，相关人员会对您进行必要的身份验证后，在符合相关法律规定且技术可行的前提下，将个人信息副本提交给您。如您请求将您的个人信息转移至您指定的个人信息处理者，符合国家网信部门规定条件的，我们将提供转移的途径。
</p>
    <h4>4.3. 变更、撤回您的同意范围</h4>


    <p>如您希望，您可以变更您同意授权我们处理您的个人信息的范围，您可以通过下述“联系我们“所载明的联系方式与我们取得联系，变更或撤回或您的授权。请您注意的是，当您撤回某些个人信息处理的同意时，可能导致我们无法正常为您提供某些服务。
    </p><p>当收到您的撤回同意后，我们将不再处理您相应的个人信息，请知悉，您撤回同意后不影响在此之前我们对您个人信息进行的收集和处理。
</p>
    <h4>4.4. 删除您的个人信息</h4>
    <p>当存在以下情形时，您可以向我们提出删除您个人信息的请求：
    </p><p>（1）您的个人信息对于实现收集或处理的目的已不再必要；
    </p><p>（2）您已经撤回我们对您个人信息处理的同意；
    </p><p>（3）我们停止提供产品或服务，或者保存期限已届满；
    </p><p>（4）我们违反法律、行政法规或违反约定处理个人信息；
    </p><p>（5）法律、行政法规规定的其他情形。
    </p><p>根据适用法律要求，我们有义务对您的个人信息进行删除。出于技术原因，我们可能无法立即删除您的个人信息。我们将在系统更新时删除这些个人信息。在收到您删除个人信息的请求后至系统更新期间，我们将不再对您的个人信息（已收集）做任何处理。
</p>
    <h4>4.5. 响应您的请求</h4>
    <p>您希望行使上述权利时，可以通过“联系我们”中所提供的联系方式与我们联系。在收到您的请求后，我们可能会首先验证您的身份，并可能会要求您提供必要的信息以进行身份验证。我们会在收到您的请求之后尽快与您取得联系。如我们有理由认为无需对您的请求采取任何措施，我们也会向您告知我们的决定，并向您做出相应的解释。我们将尽最大努力帮助您解决相关问题。如您对我们的答复不满意，您可以向相关监管机关投诉，或向有管辖权的司法机关提起诉讼。
</p>
    <h3>五、未成年人个人信息的处理</h3>
    <p>我们的产品和服务主要面向成人。尽管不同地区法律和习俗对儿童的定义不同，但我们将不满14周岁的任何人均视为儿童。我们不允许未满14周岁的儿童注册账号或使用我们的产品和服务，并且不会有意收集任何未满周14岁儿童的信息。
    </p><p>如果任何时候监护人有理由相信我们收集了未成年人的个人信息，请通过下述“联系我们“所载明的联系方式联系我们，我们会采取措施尽快删除相关信息。
</p>
    <h3>六、联系我们</h3>
    <p>如您对本政策存在任何问题或建议，或者发现任何违反本政策的行为，请随时联系我们，我们为您提供了多种反馈通道，希望为您提供满意的解决方案：
    </p><p>· 客服电话：您可以拨打400 009 0909与我们联系；
    </p><p>· 办公地址：浙江省杭州市滨江区聚光中心A座12楼；
    </p><p>一般情况下，我们将在十五个工作日内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向监管部门进行投诉或举报。
</p>
    <h3>七、隐私政策的更新</h3>
    <p>我们将不时对本政策进行变更、修改。如该等变更或修改会导致您在本政策项下权利的实质减损，我们将在变更或修改生效前，通过在页面显著位置提示等方式通知您。届时，请您务必完整阅读和充分了解更新或修改后的隐私政策。未经您明确同意，我们不会削减您按照本隐私政策所应享有的权利。
    </p><p>本隐私政策所指的重大变更包括：
    </p><p>1. 我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；
    </p><p>2. 个人信息共享、转让或公开披露的主要对象发生变化；
    </p><p>3. 您参与个人信息处理方面的权利及其行使方式发生重大变化；
    </p><p>4. 其他可能对您的个人信息权益产生重大影响的变化。
</p>
  </section>
</template>
<script>
export default {
  data() {
    return {
      loading:true
    };
  },
  computed:{
      
  },
  mounted(){
    this.loading = false
  }
}
</script>
<style lang="scss" scoped>
.reg {
  background: #fff;
  padding: 16px;
  text-align: left;
  font-size: 13px;
  h2 {
    font-size: 18px;
    margin: 0 0 16px;
    text-align: center;
  }
  h3 {
    font-size: 14px;
    margin: 8px 0;
  }
  h4 {
    font-size: 13px;
  }
  p{
    margin: 4px 0;
  }
}
</style>
